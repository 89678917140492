@media (max-width: 1160px) {
    .pdbDescription {
        grid-column: 3 / span 4;
        width: 90%;
    }

    .designMenuList1 {
        grid-column: 1;
    }
}

@media (max-width: 1100px) {
    .phoDauBo {
        height: min-content;
    }
    .pdbDetails {
        margin-top: 5%;
    }
}

@media (max-width: 980px) {
    .pdbHeading2 {
        display: none;
    }

    .vietKitchen2 {
        display: none;
    }

    .pdbMenu1 {
        grid-column: 2 / span 4;
        justify-self: start;
    }

    .pdbMenu2 {
        grid-column: 2 / span 4;
        justify-self: start;
    }

    .pdbMenuDescription {
        grid-column: 4;
        justify-self: start;
        align-self: center;
        grid-row: 4;
    }

    .pdbLayoutImage {
        margin-top: 150px;
        margin-right: 50px;
        grid-row: 4 / span 5;
        grid-column: 2 / span 4;
        align-self: center;
        justify-self: end;
    }

    
}

@media (max-width: 936px) {
    .pdbHeading {
        margin-top: 0;
    }

    .pdbDescription {
        grid-row: 2;
        grid-column: 2 / span 4;
        font-size: 16px;
        width: 70%;
    }

    .onTheMenuHeading {
        margin-top: 50px;
    }

    .pdbMenuDescription {
        grid-row: 6;
        grid-column: 2 / span 3;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .pdbLayoutImage {
        grid-row: 7;
        grid-column: 2/span 3;
        justify-self: center;
        margin-top: -20px;
        margin-bottom: 80px;
    }

}
@media (max-width: 777px) {
    .pdbLogo {
        grid-column: 2 / span 3;
        justify-self: center;
        margin-top: 30px;
        margin-left: 0;
    }

    .customerQuote {
        grid-column: 2 / span 3;
        grid-row: 3;
        margin-top: 20px;
        width: 80%;
    }

    .customerQuoteAuthor {
        font-size: 19px;
    }

    .pdbHeading {
        font-size: 67px;
        line-height: 70px;
    }

    .vietKitchen {
        display: none;
    }

    .vietKitchenMobile {
        display: block;
        grid-column: 2 / span 3;
        grid-row: 2;
        align-self: start;
        justify-self: start;
        margin: -30px 0 200px 100px; 
    }

    .onTheMenuHeading {
        font-size: 43px;
    }
}

@media (max-width: 705px) {
    .onTheMenuHeading {
        grid-column: 1 / span 4;
        margin-top: 50px;
        font-family: ChapLight;
        font-size: 43px;
        width: 70%;
    }

    .designMenuList1 {
        grid-column: 1;
    }

    .designMenuList2 {
        grid-column: 2 / span 3;
    }

}

@media (max-width: 684px) {
    .pdbMenu1, .pdbMenu2 {
        display: none;
    }

    .pdbMenu1_Mobile {
        display: block;
        grid-row: 4;
        grid-column: 2 / span 4;
        justify-self: end;
    }

    .pdbMenu2_Mobile {
        display: block;
        grid-row: 5;
        grid-column: 2 / span 4;
        justify-self: end;
    }

}

@media (max-width: 624px) {
    .pdbDescription {
        justify-self: start;
        margin-left: 20px;
    }

    .onTheMenuDiv {
        margin-top: 80px;
    }

    .onTheMenuHeading {
    }

    .designMenuList1 {
        grid-column: 1 / span 2;
    }

    .designMenuList2 {
        grid-column: 1 / span 2;
        grid-row: 3;
        align-self: start;
        margin: 0;
    }

}

@media (max-width: 500px) {
    .phoDauBo {
        margin-bottom: 0px;
    }
    .pdbLogo {
        grid-column: 1 / span 3;
        width: 280px;
    }

    .customerQuote {
        grid-column: 1 / span 3;
    }


    .pdbHeading {
        grid-column: 1 / span 2;
    }

    .vietKitchenMobile {
        grid-column: 1 / span 2;
        margin-left: 50px;
        margin-top: -15px;
    }

    .pdbDescription {
        grid-column: 1 / span 4;
        margin-top: 20px;
    }

    .onTheMenuDiv {
        grid-column: 1 / span 4;
    }


}

@media(max-width: 380px) {
    .phoDauBo {
        margin-bottom: 250px;
    }

    .pdbMenuDescription {
        grid-column: 1 / span 4;
        width: 80%;
    }

}