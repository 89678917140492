@media (max-width: 974px) {
    .mtom {
        font-size: 67px;
        line-height: 50px;
        margin-top: -20px;
    }

    .wfmHours {
        font-size: 54px;
    }

    .wfmDescription {
        font-size: 16px;
    }

    .wfmImageContainer {
        height: 270px;
        width: 60vw;
    }
}

@media (max-width: 623px) {
    .mtom {
        font-size: 67px;
    }
    .wfmThanks {
        width: 100%;
    }

    .wfmImageContainer {
        height: 200px;
    }
}

@media (max-width: 500px) {
    .mtom {
        grid-column: 1 / span 2;
    }

    .wfmDescription {
        grid-column: 1 / span 2;
    }

    .wfmImageContainer {
        grid-column: 1 / span 2;
        height: 200px;
        /* width: max-content; */
    }

    .wfmImage {
        object-fit: cover;
    }
}