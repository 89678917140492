.phoDauBo {
    display: grid;
    grid-template-columns: minmax(88px, 10%) 1fr 1fr;
    grid-template-rows: repeat(3, min-content);
    max-height: 124vmin;
}

.pdbLogo {
    grid-column: 2 / span 3;
    grid-row: 2;
    justify-self: center;
    width: 45.588281249999994VW;
    margin-left: 200px;
}

.customerQuote {
    font-family: Cerabasic;
    font-size: 24px;
    grid-column: 2;
    grid-row: 2;
    align-self: end;
    justify-self: start;
    margin-left: 20px;
    width: 70%;
}

.customerQuoteAuthor {
    float: right;
}

.pdbDetails {
    margin-top: 200px;
    display: grid;
    grid-template-columns: minmax(88px, 10%) 1fr 1fr 1fr;
    grid-template-rows: repeat(6, min-content);
}

.pdbHeading {
    font-family: ChapLight;
    font-size: 110px;
    grid-column: 2;
    grid-row: 1;
    line-height: 110px;
    width: min-content;
    margin-left: 20px;
}

.pdbDescription {
    font-family: Cerabasic;
    font-size: 23px;
    grid-column: 2 / span 4;
    grid-row: 1;
    align-self: center;
    justify-self: center;
    width: 50%;
}

.vietKitchen {
    grid-column: 2 / span 3;
    grid-row: 2;
    align-self: start;
    justify-self: start;
    margin: -30px 0 200px 100px;
}

.vietKitchenMobile {
    display: none;
}

.onTheMenuDiv {
    grid-column: 2 / span 4;
    grid-row: 3;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.onTheMenuHeading {
    font-family: ChapLight;
    font-size: 67px;
    margin-left: 20px;
    grid-column: 1 / span 3;
}

.designMenuList1 {
    font-family: Cerabasic;
    font-size: 23px;
    grid-column: 1 / span 2;
    justify-self: center;
    grid-row: 2;
    margin-top: 30px;
}

.designMenuList1 li {
    margin-bottom: 20px;
}

.designMenuList2 {
    font-family: Cerabasic;
    font-size: 23px;
    grid-column: 2 / span 3;
    justify-self: center;
    grid-row: 2;
    margin-top: 30px;
}

.designMenuList2 li {
    margin-bottom: 20px;
}

.pdbMenu1 {
    grid-column: 2 / 4;
    grid-row: 4;
    justify-self: center;
    margin-top: 100px;
}

.pdbMenu1_Mobile {
    display: none;
}

.pdbMenu2_Mobile {
    display: none;
}

.pdbMenu1_Tablet {
    display: none;
}

.pdbMenu2_Tablet {
    display: none;
}

.pdbHeading2 {
    grid-column: 4;
    grid-row: 4;
    margin-top: 75px;
    font-family: ChapLight;
    font-size: 110px;
    line-height: 110px;
    width: min-content;
}

.pdbMenu2 {
    grid-column: 2 / 4;
    grid-row: 5;
    justify-self: center;
    margin-top: 50px;
}

.pdbMenuDescription {
    grid-column: 4;
    grid-row: 5;
    width: 60%;
    font-family: Cerabasic;
    font-size: 16px;
    margin-left: 20px;
    z-index: 1;
}

.vietKitchen2 {
    grid-column: 2 / 4;
    grid-row: 6;
    justify-self: center;
    margin-top: 80px;
    margin-bottom: 200px;
    z-index: 1;
}

.pdbLayoutImage {
    grid-column: 4;
    grid-row: 5 / span 6;
    align-self: center;
    width: min-content;
    margin-left: -30px;
}
