@media (max-width: 1017px) {
    .crossingImageContainer {
        grid-column: 2 / span 3;
        grid-row: 4;
        justify-self: center;
        margin-bottom: 0;
        margin-top: 50px;
    }

    .logoUtil {
        grid-column: 2 / span 3;
        grid-row: 5;
        margin-bottom: 100px;
    }

    .crossingOriginalLogoDescription,
    .crossingNewLogoDescription {
        width: 90%;
    }
}

@media (max-width: 867px) {
    .crossingOriginalLogoDiv {
        grid-column: 2 / span 3;
    }
    
    .crossingOriginalLogoDescription {
        grid-column: 2 / span 3;
        grid-row: 3;
        width: 70%;
        justify-self: center;
        margin-bottom: 50px;
    }

    .crossingNewLogoDiv {
        grid-column: 2 / span 3;
        grid-row: 4;
    }

    .crossingNewLogoDescription {
        grid-column: 2 / span 3;
        grid-row: 5;
        justify-self: center;
        width: 70%;
        margin-top: 30px;
    }

    .crossingImageContainer {
        grid-row: 6;
    }

    .logoUtil {
        grid-row: 7;
        width: 70%;
    }
}

@media (max-width: 734px) {
    .crossingArrowDown {
        margin-right: 20px;
        grid-column: 3;
        grid-row: 2;
    }

    .crossingHeading {
        font-size: 55px;
        line-height: 55px;
    }
}

@media (max-width: 650px) {
    .crossingCaption {
        display: block;
        grid-column: 2;
        grid-row: 2;
        align-self: center;
        font-family: ChapLight;
        font-size: 25px;
        width: 221px;
        margin-left: 10px;
    }

    .crossingOriginalLogo,
    .crossingNewLogo {
        display: none;
    }

    .crossingOriginalLogoDescription {
        grid-row: 2;
    }

    .crossingsOldLogoMobile {
        display: block;
    }

    .crossingOriginalLogoDiv {
        grid-row: 3;
        grid-column: 2;
        justify-self: end;
        margin-right: 5px;
    }

    .crossingsNewLogoMobile {
        display: block;
    }

    .crossingNewLogoDiv {
        grid-row: 3;
        grid-column: 3;
        justify-self: start;
        margin-left: 5px;
    }

    .crossingNewLogoDescription {
        grid-row: 4;
    }

    .crossingImageContainer {
        display: none;
    }

    .crossingImageContainerMobile {
        display: block;
        grid-row: 5;
        grid-column: 2 / span 3;
        justify-self: center;
        margin-top: 50px;
        /* margin-left: 48px; */
    }

    .logoUtil {
        grid-row: 7;
        grid-column: 2 / span 3;
        margin-top: 20px;
        text-align: center;
    }


}

@media (max-width: 500px) {
    .crossing {
        background-position: left bottom;
    }
    .crossingCaption {
        grid-column: 1 / span 2;
    }

    .crossingHeading {
        grid-column: 1 / span 3;
    }

    .crossingOriginalLogoDescription {
        grid-column: 1 / span 3;
    }

    .crossingOriginalLogoDiv {
        grid-column: 1 / span 2; 
        justify-self: center;
    }

    .crossingNewLogoDiv {
        grid-column: 2 / span 3;
        justify-self: center;
    }

    .crossingNewLogoDescription {
        grid-column: 1 / span 3;
    }

    .crossingImageContainerMobile {
        grid-column: 1 / span 3;
    }

    .logoUtil {
        grid-column: 1 / span 3;
    }
}