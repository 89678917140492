.rebel {
    display: grid;
    grid-template-columns: minmax(88px, 10%) 1fr;
    grid-template-rows: 1fr 1fr;
    background-image: url('../../../../images/rebelBG.png');
    background-position: center;
    background-repeat: no-repeat;
    /* background-size: cover; */
}

.steveJobsQuote {
    grid-column: 2;
    grid-row: 2;
    align-self: center;
    margin-left: 20px;
    width: 30%;
    font-family: Cerabasic;
    font-size: 24px;
}

.steveJobsQuoteAuthor {
    font-size: 19px;
}

.rebelDetails {
    display: grid;
    grid-template-columns: minmax(88px, 10%) 1fr 1fr;
    grid-template-rows: repeat(10, min-content);
}

.rebelHeading {
    grid-column: 2/ span 3;
    grid-row: 1;
    font-size: 110px;
    /* font-size: 8.59375VW; */
    font-family: ChapLight;
    width: min-content;
    line-height: 110px;
    margin-left: 20px;
    margin-top: 50px;
}

.rebelDescription {
    grid-column: 2;
    grid-row: 2;
    font-family: Cerabasic;
    font-size: 23px;
    width: 254px;
    justify-self: start;
    margin-top: 50px;
    margin-left: 30px;
}

.storylineImage {
    margin-top: 50px;
    grid-column: 2 / span 3;
    grid-row: 2;
    margin-left: 100px;
    justify-self: center;
}

.storylineImageMobile {
    display: none;
}

.iconHeading {
    font-family: ChapLight;
    font-size: 110px;
    grid-column: 2;
    grid-row: 3;
    margin-left: 20px;
    margin-top: 100px;
}

.rebelImageContainer {
    grid-column: 2 / span 3;
    grid-row: 4;
    justify-self: center;
    cursor: pointer;
    position: relative;
}

.rebelDotContainer {
    position: absolute;
    bottom: 10px;
    right: 0;
}


.rebelLogoDescription {
    grid-column: 2 / span 3;
    grid-row: 5;
    width: 80%;
    justify-self: center;
    margin-top: 100px;
    font-family: Cerabasic;
    font-size: 23px;
}

.rebelSocialMediaHeading {
    grid-column: 2;
    grid-row: 6;
    justify-self: center;
    width: min-content;
    font-size: 110px;
    font-family: ChapLight;
    line-height: 110px;
    margin-top: 200px;
}



.rebelSocialMediaImage {
    grid-column: 3;
    grid-row: 6;
    margin-top: 200px;

}

.rebelScreenShot {

    grid-column: 2 / span 3;
    grid-row: 7;
    justify-self: center;

    margin: -300px 350px 200px 0;
}

