.workingForMyselfDiv {
    display: grid;
    grid-template-columns: minmax(88px,10%) 1fr;
    background-image: url('../../../../images/introbg.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.headerDiv {
    align-self: start !important;
    margin-top: 51px;
}

.wfmDetails {
    display: grid;
    grid-template-columns: minmax(88px, 10%) 1fr;
    grid-template-rows: repeat(3, min-content);
}

.mtom {
    font-size: 110px;
    font-size: 8.052708638360176VW;
    font-weight: normal;
    font-family: ChapLight;
    grid-column: 2;
    grid-row: 1;
    justify-self: start;
    margin-left: 10px;
    margin-top: -40px;
    line-height: 90px;
    color: white;
}

.wfmDescription {
    font-size: 23px;
    font-family: Cerabasic;
    grid-column: 2;
    grid-row: 2;
    width: 65%;
    justify-self: center;
    padding-top: 80px;
    padding-bottom: 80px;
    color: white;
}

.wfmImageContainer {
    grid-row: 3;
    grid-column: 2;
    width: 70vw;
    max-width: 693px;
    max-height: 471px;
    height: 80vh;
    /* width: 693px;
    height: 471px; */
    /* width: 70vw;
    max-width: 70vw;
    min-width: 320px;
    height: 80vh;
    max-height: 80vh;
    min-height: 213px; */
    justify-self: center;
    position: relative;
    background-color: white;
}

.wfmImage  {
    height: 100%;
    width: 100%;
    object-fit: cover;
    animation: fading 1.6s;

}


.wfmImageDisplayNone {
    display: none;
}

@keyframes fading {
  from {opacity: 0.1;}
  to {opacity:1;}
}


.wfmThanks {
    font-size: 12px;
    grid-column: 2;
    grid-row: 4;
    justify-self: center;
    width: 75%;
    margin-bottom: 100px;
}

.dotContainer {
    width: max-content;
    position: absolute;
    bottom: 0;
    right: 0;
}

.dot {
    background-color: black;
    border: 1px solid black;
}

.dotClear {
    background-color: transparent;
    border: 1px solid black;
}