.practiceSpot {
    background-image: url('../../../../images/practiceSpotBG.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.practiceSpotDetails {
    display: grid;
    grid-template-columns: minmax(88px, 10%) 1fr 1fr 1fr;
    grid-template-rows: repeat(8, min-content);
}

.practiceSpotHeading {
    grid-column: 2 / span 4;
    grid-row: 1;
    margin-top: 50px;
    font-family: ChapLight;
    font-size: 110px;
    font-weight: normal;
    text-align: center;
    color: white;
}

.practiceSpotDescription {
    grid-column: 3 / span 4;
    grid-row: 2;
    font-family: Cerabasic;
    font-size: 23px;
    color: white;
    width: 90%;
    justify-self: center;
    margin-bottom: 100px;
}

.practiceSpotUserHeading {
    grid-column: 3 / span 4;
    grid-row: 3;
    justify-self: end;
    margin-right: 80px;
    font-family: ChapLight;
    font-size: 110px;
    font-weight: normal;
    color: white;
}

.practiceSpotUserDescription {
    grid-column: 3 / span 4;
    grid-row: 4;
    font-family: Cerabasic;
    font-size: 23px;
    color: white;
    width: 80%;
    z-index: 1;
}

.practiceSpotUserImage {
    grid-column: 2 / span 3;
    grid-row: 4;
}

.initialConceptHeading {
    grid-column: 2 / span 4;
    grid-row: 5;
    justify-self: start;
    margin-left: 100px;
    font-family: ChapLight;
    font-size: 110px;
    font-weight: normal;
    color: white;
}

.initialConceptDescription {
    grid-column: 2 / span 3;
    grid-row: 6;
    justify-self: start;
    margin-left: 100px;
    width: 50%;
    font-family: Cerabasic;
    font-size: 23px;
    color: white;
    margin-bottom: 350px;
}

.initialConceptImage {
    grid-column: 4;
    grid-row: 5 / span 6;
}

.leftBlock {
    /* grid-column: 2;
    grid-row: 7;
    border: 1px solid purple;
    height:700px;
    background-color: black;
    z-index: 1; */
}

.tpsImageBannerContainer {
    padding-left: 20px;
    grid-column: 2 / span 4;
    grid-row: 7;
    z-index: 0;
    height: 482px;
    width: 90%;
    overflow-x: hidden;
    position: relative;
    justify-self: center;
}

.tpsNextBtn {
    position: absolute;
    top: 40%;
    right: 0;
    z-index: 1;
    background: none;
    border: none;
}

.tpsPrevBtn {
    position: absolute;
    top: 40%;
    left: 0;
    z-index: 1;
    transform: rotate(180deg);
    background:none;
    border: none;
}

.practiceSpotImageBanner {
    /* border: 2px solid yellow; */
    /* max-width: 350px; */
    position: relative;
}

.practiceSpotImageBanner::after {
    content: '';
    width: 100%;
    height: 482px;
    /* outline: 5px solid green; */
    position: absolute;
    top: 0;
    left: 0;
}

.tpsCardWrapper {
    display: flex;
    /* border: 3px solid blue; */
    position: absolute;
    transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    z-index: -100;
}

.tpsCardDiv {
    flex: 1;
    min-width: 350px;
    opacity: 0.5;
    transform: scale(0.9);
    transition: opacity 300ms linear, transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.practiceSpotImageBanner.active-card-0 #tpsBannerDiv1 {
    opacity: 1;
    transform: scale(1);
}
.practiceSpotImageBanner.active-card-1 #tpsBannerDiv2 {
    opacity: 1;
    transform: scale(1);
}
.practiceSpotImageBanner.active-card-2 #tpsBannerDiv3 {
    opacity: 1;
    transform: scale(1);
}
.practiceSpotImageBanner.active-card-3 #tpsBannerDiv4 {
    opacity: 1;
    transform: scale(1);
}



.tpsBannerListItem {
    float: left;
}

.tpsCardTitle {
    color: white;
    font-size: 24px;
    font-weight: bold;
}

.tpsCardDescription {
    color: white;
    font-size: 24px;
}