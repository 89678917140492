.sandwichContainer {
    position: absolute;
    right: 0;
    top:0;   
}

.sandwichComp {
    width: 250px;
    background-color: black;
    position: fixed;
    height: 110vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(5, min-content);
    transform: translateX(-100%);
    transition: transform 0.6s ease-out;
    z-index: 10;
    /* border-left: 1px solid #D9D9D9; */
}

.sandwichComp.off {
    display: grid;
    transform: translateX(100%);
    overflow-y: hidden;
}

.sandwichComp h4 {
    font-family: ChapLight;
    font-weight: normal;
    font-size: 49px;
    line-height: 40px;
    margin-top: 10px;
    color: white;
}

#sandwichMenuExit {
    cursor: pointer;
    grid-column: 2;
    grid-row: 1;
    color: white;
}


#sandwichSarah {
    grid-column: 1;
    grid-row: 1;
    justify-self: center;
}

#sandwichAlonso {
    grid-column: 1 / span 2;
    grid-row: 2;
    justify-self: center;
    align-self: end;
}

#sandwichDesign {
    grid-column: 1;
    grid-row: 3;
    justify-self: center;
    align-self: start;
    margin-left: 10px;
    /* margin-top: -12px; */
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #707070;
}

#sandwichCloseBtnContainer {
    grid-column: 1;
    grid-row: 1/-1;
    align-self: start;
    /* border: 1px solid white; */
    width: min-content;
    padding-right: 20px;
    margin-top: 20px;
    cursor: pointer;
}

#sandwichCloseBtnContainer p {
    color: white;
    font-size: 24px;
    margin-left: 20px;
}

#sandwichLinks {
    padding: 0;
    /* margin-top: -200px;
    margin-left: -50px; */
    margin-top: 150px;
    text-align: left;
    list-style-type: none;
    grid-column: 1/ span 2;
    grid-row: 4;
    line-height: 55px;
    font-family: ChapLight;
    font-size: 30px;
    color: white;
    align-self: center;
    justify-self: center;
}

#sandwichLinks a {
    text-decoration: none;
}

#sandwichLinks li {
    padding-left: 10px;
    padding-bottom: 0px;
    /* grid-row: 5; */
    grid-column: 1 / span 2;
    justify-self: center;
}

.workLink:hover .workArrow {
    transform: rotate(90deg);
    transition: transform 0.7s ease;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg); 
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}

.workArrow {
    color: #d9d9d9;
    transition: 0.7s;
    display: inline-block;
    cursor: pointer;
    margin-left: 20px;
    width: min-content;
    height: min-content;
}

.workArrow:hover{
    transform: rotate(90deg);
    transition: transform 2s ease;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg); 
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}

.workLinkMenu {
    visibility: hidden;
    opacity: 0;
    padding: 0;
    width: min-content;
    transition: visibility 0s, opacity 1s ease-in, height 2s ease;
    font-size: 16px;
    line-height: 20px;
    height: 0;
}

.workLinkMenu ul {
    list-style-type: none;
}

#workLinkMenu.displayWorkLinks {
    visibility: visible;
    opacity: 1;
    height: min-content;
}

.workLinksList li {
    margin-bottom: 20px;
}

#uxUiLink {
    margin-bottom: 20px;
}

#graphicDesignLink {
    margin-bottom: 20px;
}

#contactLink {
    margin:0;
    transition: margin 0.7s;
}

#contactLink.moveDown {
    margin-top: 10px;
}

@media (min-width: 600px) and (max-width: 799px) {
    .sandwichComp h4 {
        margin-left: 2px;
    }

    #sandwichSarah {
        justify-self: start;
    }

    #sandwichAlonso {
        margin-right: 50px;
    }

    #sandwichDesign {
        justify-self: start;
    }

    #sandwichLinks {
        margin-left: -85px;
    }
}


@media (min-width: 450px) and (max-width: 599px){

    .sandwichContainer {
        grid-column: 1 / span 3;
    }

    .sandwichComp {
        width: 100%;    
    }

    #sandwichMenuExit {
        grid-column: 2;
        justify-self: center;
    }
    #sandwichSarah {
        justify-self: center;
        grid-column: 1 / span 2;
        margin-top: 20px;
    }
    
    #sandwichAlonso {
        grid-column: 2;
        justify-self: start;
    }

    #sandwichDesign {
        grid-column: 1 / span 2;
        justify-self: center;
    }

    #sandwichLinks {
        justify-self: center;
        align-self: start;
        grid-column: 1 / span 2;
        margin-top: 100px;
        margin-left: 10px;
    } 
}

@media (min-width: 330px) and (max-width: 449px) {
    .sandwichContainer {
        grid-column: 1 / span 3;
    }

    .sandwichComp {
        width: 100%; 
        /* height: 120%;    */
    }

    #sandwichMenuExit {
        grid-column: 2;
        justify-self: center;
    }
    #sandwichSarah {
        justify-self: center;
        grid-column: 1 / span 2;

    }
    
    #sandwichAlonso {
        grid-column: 2;
        justify-self: start;
    }

    #sandwichDesign {
        grid-column: 1 / span 2;
        justify-self: center;
    }

    #sandwichLinks {
        justify-self: center;
        align-self: start;
        grid-column: 1 / span 2;
        margin-left: 10px;
    }   
}

@media (max-width: 329px) {
    .sandwichContainer {
        grid-column: 1 / span 3;
    }

    .sandwichComp {
        width: 100%;    
    }

    #sandwichMenuExit {
        grid-column: 2;
        justify-self: center;
    }
    #sandwichSarah {
        justify-self: center;
        grid-column: 1 / span 2;
        margin: 0;
    }
    
    #sandwichAlonso {
        grid-column: 2;
        justify-self: start;
    }

    #sandwichDesign {
        grid-column: 1 / span 2;
        justify-self: center;
    }

    #sandwichLinks {
        justify-self: center;
        align-self: start;
        grid-column: 1 / span 2;
        margin-left: 10px;
    }      
}

/*****************
HEIGHT PROPERTIES
*****************/

@media (max-height: 500px) {
    #sandwichLinks {
        margin-top: 50px;
    }
}

@media (max-height: 300px) {
    #sandwichLinks {
        grid-row: 3;
        align-self: start;
        margin-top: 0;
    }
    .sandwichComp h4 {
        display: none;
    }
}

