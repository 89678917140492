.headerDiv {
    margin-left: 10px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    grid-column: 2 / span 3;
    grid-row: 1;
    justify-self: start;
    align-self: center;
    line-height: 85px;
    width: 75%;
}

.headerDiv header {
  /* padding-left: 5px; */
  justify-self: start;
  align-self: center;
  line-height: 85px;
  width: 55%;
}

header h1 {
    /* margin: 0; */
    padding: 0;
    font-family: ChapLight;
    font-size: 87px;
    font-size: 6.359649122807018VW;
    font-weight: normal;
    line-height: 6.359649122807018VW;
}

header .sarah {
  float: left;
  width: 100%;
}

header .alonso {
  float: right;
  width: 75%;

}

header .design {
  width: 100%;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  float: left;
}