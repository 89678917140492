.crossing {
    display: grid;
    grid-template-columns: minmax(88px, 10%) 1fr;
    background-image: url('../../../../images/crossingBG.png');
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: center; */
}

.crossingCaption {
    grid-column: 2;
    grid-row: 2;
    align-self: center;
    font-family: ChapLight;
    font-size: 25px;
    width: 221px;
    margin-left: 10px;    
}

.crossingArrowDown {
    grid-column: 2;
    justify-self: end;
    align-self: end;
    margin-right: 200px;
}

.crossingDetails {
    display: grid;
    grid-template-columns: minmax(88px, 10%) repeat(2, 1fr);
    grid-template-rows: repeat(4, min-content);
}

.crossingHeading {
    grid-column: 2;
    grid-row: 1;
    width: min-content;
    margin-left: 20px;
    margin-bottom: 50px;
    font-family: ChapLight;
    font-size: 110px;
    font-size: 8.59375VW;
    line-height: 8.59375VW;
}

.crossingOriginalLogoDiv {
    grid-column: 2;
    grid-row: 2;
    justify-self: center;
    text-align: center;
    margin-bottom: 20px;
}

.crossingsOldLogoMobile {
    display: none;
}

.crossingOriginalLogoDescription {
    grid-column: 3;
    grid-row: 2;
    width: 70%;
    font-family: Cerabasic;
    font-size: 16px;
}

.crossingNewLogoDiv {
    grid-column: 2;
    grid-row: 3;
    justify-self: center;
    text-align: center;
}

.crossingsNewLogoMobile {
    display: none;
}


.crossingNewLogoCaption {
    grid-column: 2;
    grid-row: 3;
    justify-self: center;
    align-self: end;
}

.crossingNewLogoDescription {
    grid-column: 3;
    grid-row: 3;
    width: 70%;
}

.crossingNewLogoDescription span {
    font-weight: bold;
}

.logoUtil {
    grid-column: 2;
    grid-row: 4;
    width: 40%;
    justify-self: center;
    align-self: center;
}

.crossingImageContainer {
    grid-column: 3;
    grid-row: 4;
    align-self: end;
    margin-bottom: 100px;
    position: relative;
    width: min-content;
    /* width: 41.640625VW;
    height: 25.703125VW; */

}

.crossingDotContainer {
    position: absolute;
    bottom: 10px;
    right: 5px;
}

.crossingImageContainerMobile {
    display: none;
    position: relative;
    width: min-content;
}

.crossingDotContainerMobile {
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.crossingDotMobile{
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 4px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.crossingDotClearMobile {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 4px;
    background-color: #454545;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}




