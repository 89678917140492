.visualCulture {
    display: grid;
    grid-template-columns: minmax(88px, 10%) 1fr;
    background-image: url('../../../../images/vcBG.png');
    background-size: cover;
    background-position: center;
}

.vcArrowDown {
    grid-column: 2;
    align-self: end;
    justify-self: end;
    margin-bottom: 30px;
    margin-right: 200px;
}

.vcDetails {
    min-height: 100vh;
    display: grid;
    grid-template-columns: minmax(88px, 10%) 1fr 1fr;
    grid-template-rows: repeat(5, min-content);
}

.vcHeading {
    font-family: ChapLight;
    font-size: 110px;
    /* font-size: 8.59375VW; */
    grid-column: 2;
    grid-row: 1;
    line-height: 110px;
    margin-left: 20px;
    margin-top: 20px;
}

.timFerrissQuote {
    color: white;
    font-size: 23px;
    font-family: Cerabasic;
    grid-column: 3;
    grid-row: 2;
    width: 85%;
}

.timFerrissCaption {
    font-size: 16px;
    font-family: Cerabasic;
    color: white;
    
}

.openingQuote, .closingQuote {
    font-family: 'Times New Roman', Times, serif;
    font-size: 50px;
}

.closingQuote {
    float: right;
}

.creativeSummary {
    grid-column: 2;
    grid-row: 3;
    width: 60%;
    font-family: Cerabasic;
    font-size: 16px;
    justify-self: start;
    margin-left: 20px;
}

.vcImageContainer {
    grid-column: 2 / span 3;
    grid-row: 4;
    margin-top: 80px;
    justify-self: center;
}

.vcImageContainer img {
    margin: 10px;
}

.vcDynamicImageContainer {
    display: none;
}

.vcLogoDescription {
    grid-column: 3;
    grid-row: 5;
    justify-self: center;
    width: 50%;
    margin-top: 20px;
    margin-bottom: 50px;
}