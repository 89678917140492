.rumble {
    display: grid;
    grid-template-columns: minmax(88px, 10%) 1fr;
    background-image: url('../../../../images/rumbleBG.png');
    background-position: center;
    background-size: cover;
}

.rumbleQuote {
    grid-column: 2;
    grid-row: 2;
    margin-left: 20px;
    margin-bottom: 50px;
    align-self: end;
    font-size: 24px;
    font-family: Cerabasic;
    width: 35%;
}

.rumbleQuoteAuthor {
    font-size: 19px;
}

.rumbleBlackArrow {
    grid-column: 2;
    grid-row: 2;
    justify-self: end;
    align-self: end;
    margin-right: 200px;
    margin-bottom: 20px;
}

.rumbleDetails {
    display: grid;
    grid-template-columns: minmax(88px, 10%) 1fr 1fr 1fr;
    grid-template-rows: repeat(9, min-content);
}

.rumbleHeading {
    grid-column: 2 / span 3;
    grid-row: 1;
    font-family: ChapLight;
    font-size: 110px;
    font-size: 8.59375VW;
    margin-left: 20px;
    margin-top: 50px;
    width: 80%;
    line-height: 110px;
    line-height: 8.59375VW;
}

.rumbleDescription {
    grid-column: 2 / span 4;
    grid-row: 2;
    width: 80%;
    justify-self: center;
    font-family: Cerabasic;
    font-size: 23px;
    margin-top: 10px;
}

.roleOnTheTeamDiv {
    grid-column: 2 / span 4;
    grid-row: 3;
    margin-top: 100px;
    margin-left: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.roleOnTheTeamHeading {
    font-family: ChapLight;
    font-size: 43px;
    grid-column: 1;
}

.roleList1, .roleList2 {
    font-family: Cerabasic;
    font-size: 23px;
}

.roleList1 li, .roleList2 li {
    margin-bottom: 20px;
}

.roleList1 {
    grid-column: 2;
}

.roleList2 {
    grid-column: 3;
}

.earlyBirdContainer {
    grid-column: 2 / span 4;
    grid-row: 4;
    width: 80%;
    justify-self: center;
    margin-top: 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

#ebPhone1{
    grid-column: 1;
    grid-row: 1;
    margin-top: -50px;
}

#ebPhone2 {
    grid-column: 1;
    grid-row: 1 / span 2;
    align-self: center;
    margin-top: -100px;
    margin-left: 80px;
}

#ebPhone3 {
    grid-column: 1;
    grid-row: 2;
    margin-top: -50px;
}

.earlyBirdHeading {
    grid-column: 1 / span 2;
    grid-row: 1;
    justify-self: end;
    width: 80%;
    font-size: 82px;
    font-size: 6.406249999999999VW;
    font-family: ChapLight;
    color: #E5E5E5;
    line-height: 6.406249999999999VW;
}

.earlyBirdDescription {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
    align-self: center;
    justify-self: end;
    width: 70%;
    margin-top: 100px;
    
    font-family: Cerabasic;
    font-size: 23px;
}

.celebrityContainer {
    grid-column: 2 / span 4;
    grid-row: 5;
    justify-self: end;
    width: 75%;
    margin-top: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.greyBGDiv {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
    background-color: #383838;
    width: 100%;
    height: 100%;
    justify-self: center;
    align-self: start;
}

.celebrityHeading {
    grid-column: 1;
    grid-row: 1;
    margin-left: -20px;
    color: #E5E5E5;
    font-family: ChapLight;
    font-size: 82px;
    font-weight: normal;
    z-index: 1;
}

.celebrityDescription {
    font-family: Cerabasic;
    font-size: 24px;
    grid-column: 1;
    grid-row: 1 / span 2;
    align-self: end;
    /* margin-top: -30px; */
    width: 95%;
    margin-left: 50px;
    z-index: 1;
    padding-bottom: 10px;
}

.celebrityImage {
    grid-column: 2;
    grid-row: 1/span2;
    justify-self: center;
    margin-top: -100px;
    /* margin-right: -300px; */
}

.celebrityImageMobile {
    display: none;
}

.rebrandingContainer {
    margin-top: 100px;
    margin-left: 20px;
    grid-column: 2 / span 4;
    grid-row: 6;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, min-content);
}

.rebrandingHeading {
    font-family: ChapLight;
    font-size: 82px;
    line-height: 82px;
    grid-column: 1;
    grid-row: 1;
}

.rebrandingImageContainer {
    grid-column: 1;
    grid-row: 2;
    position: relative;
    cursor: pointer;
}


.rumbleDotContainer {
    position: absolute;
    bottom: -30px;
    right: 0;
}

#rumbleDot {
    background-color: white;
}

#rumbleDotClear {
    border: 1px solid white;
}

.rebrandingDescription {
    grid-column: 2;
    grid-row: 2;
    margin-left: 20px;
    width: 70%;
    font-size: 23px;
    font-family: Cerabasic;
}



